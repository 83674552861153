<template>
    <div class="notification-list">
        <global-page-back
            detailPageTitle="返回"
            @handlerGlobalType="handlerGlobalType"
        >

        </global-page-back>

        <div class="main-content">
            <tabbed-page
                v-show="showTab"
                :tabList="tabList"
                @changeTabs="changeTabs"
            >
            </tabbed-page>

            <div class="content-main" style="margin-top: 0px;">
                <div v-if="!errorShow" class="right-panel">
                    <loading :loadingShow="loadingShow"></loading>
                    <div v-if="!loadingShow">
                        <expand-filter
                            :formData="formData"
                            @clickBtn="clickBtn"
                            @changeBtnFormType="changeBtnFormType"
                            @changeSel="changeSel"
                            :closeWidth="1250"
                            marginBottom="0px"
                            borderRadius="0px"
                            class="expand-filter-wrapper"
                        ></expand-filter>
                        <table-data :config="table_config" :tableData="table_data" v-loading="loadingTable"
                            backgroundHeader="#FAFBFC" :headerStyle="{ height: '50px' }" :rowStyle="{ height: '50px' }" ref="table"
                            id="table" :to-top="false">
                            <template #content="{data}">
                                <!-- <el-button
                                    type="text"
                                    @click="clickTitle(data)"
                                >
                                    {{ data.content }}
                                </el-button> -->
                                <el-tooltip class="item" effect="dark" :content="data.content" :disabled="data.content.length<20" placement="top">
                                    <span class="content"  @click="clickTitle(data)">
                                        {{ data.content }}
                                    </span>
                                </el-tooltip>
                            </template>

                            <!-- 消息状态 -->
                            <template v-slot:newsStatus="slotData">
                                <!-- 已接收 -->
                                <div>
                                    <div class="status-box" >
                                        <div :class="slotData.data.newsStatus == '1' ? 'green' : 'gray'"></div>
                                        <!-- <i :class="slotData.data.isReceivePaper === 1 ? 'el-icon-success' : 'el-icon-remove' || ''"></i> -->
                                        <span>{{ slotData.data.newsStatus == '1' ? '已读' : '未读' || '' }}</span>
                                    </div>

                                </div>
                            </template>
                        </table-data>
                        <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow"
                            @pagination="getList()" />
                    </div>
                </div>
                <error v-else :errorShow="errorShow"></error>
            </div>
        </div>
    </div>
</template>

<script>
    import TabbedPage from "@/components/scrollWrapper/Sub/TabbedPage/index.vue";
    import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
    import {
    Loading,
    Pagination,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
import { mapMutations,mapState } from "vuex";
import { downloadFile , formatTreeData } from "@/libs/utils";
import RecordsList from '@/libs/pageListOptimal.js';
    export default {
        name: "NotificationList",
        components: {
            TabbedPage,
            GlobalPageBack,
            Loading,
            TableData,
            Pagination,
            ExpandFilter,
        },
        data() {
            return {
                showTab: true,
                tabList: [
                    {
                        id: '15,18,22,21',
                        sel: false,
                        title: "全部消息",
                        vHasPermi: ['message:all'],
                    },
                    {
                        id: '15',
                        sel: false,
                        title: "公文管理",
                        vHasPermi: ['message:official'],
                    },
                    {
                        id: '18,22',
                        sel: false,
                        title: "出入通知",
                        vHasPermi: ['message:access'],
                    },
                    {
                        id: '21',
                        sel: false,
                        title: "巡课记录",
                        vHasPermi: ['message:courseRecord'],
                    },
                ],
                loadingShow: false,
                errorShow: false,
                loadingTable: false,

                listQuery: {
                    pageNum: 1,
                    pageRow: 20,
                    receiverId: this.$store.state.userId,
                    newsTypeList:["15","18","22","21"]
                },
                // 头部筛选
                formData: {
                    btnFormType: true,
                    formInline: {
                        teacherName: "",
                    },
                    data: [
                        {
                            type: "select",
                            label: "",
                            value: "0",
                            placeholder: "状态",
                            key: "newsStatus",
                            list: [
                                {
                                    label: "已读",
                                    value: '1'
                                }, {
                                    label: "未读",
                                    value: '0'
                                }]
                        },
                    ],
                    btnList: [{
                        type: "primary",
                        text: "全部已读",
                        fn: "primary",
                        disabled: true,
                        // auth: ["schoolLeaveRecordAnalysis:record:list"],

                    },

                    ]
                },
                table_config: {
                    thead: [{
                            label: "内容",
                            align: 'center',
                            type: "slot",
                            slotName: "content",
                            className: 'text-spacing-reduction',
                            labelWidth: '400'
                        }, {
                            label: "接收时间",
                            prop: 'createTime',
                            align: 'center'
                        }, {
                            label: "消息类型",
                            prop: "newsType",
                            align: 'center',
                            type: 'function',
                            callBack(row) {
                            return {
                                '15': "公文催办",
                                '18': "出入通知",
                                '22': "出入通知",
                                '21': "巡课记录"
                            }[+row.newsType];
                        },
                        }, {
                            label: "消息状态",
                            prop: "newsStatus",
                            type: "slot",
                            align: 'center',
                            slotName: "newsStatus"
                        }
                    ],
                    check: false,
                    height: ""
                },
                table_data: [],
                total: 0,
            };
        },
        created() {
            this.init()
            this.getList()
        },
        mounted() {
            this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 135
        },
        computed: {
            ...mapState({
                permissions: (state) => state.permissions,
                originRouters: (state) => state.originRouters,
            }),
            globalBack () {
                return require('@/assets/images/global-back.png')
            }
        },
        methods: {
            ...mapMutations(['setOAQueryData']),
            changeTabs(data) {
                this.tabList.forEach((item) => {
                    this.$set(item, "sel", item.id === data.id);
                });
                this.listQuery.newsTypeList = data.id.split(",");
                this.getList(1);
            },
            handlerGlobalType() {
                this.$router.go(-1)
            },
            init() {


                //权限
                // console.log(this.permissions, '有值吗');
                if (this.permissions.indexOf('willAttend:info') !== -1) {
                    this.detailShow = true
                }
                let permissions = this.tabList.map((item) => item.vHasPermi[0]);

                for (let i = 0; i < permissions.length; i++) {
                    if (this.permissions.indexOf(permissions[i]) !== -1) {
                        this.tabList[i].sel = true;
                        this.listQuery.newsTypeList = this.tabList[i].id.split(",")
                        return
                    }

                }
            },
            /**
             * @Description: 展开收起
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2023-03-09 11:09:53
             */
            changeBtnFormType() {
                this.formData.btnFormType = !this.formData.btnFormType;
                this.$nextTick(() => {
                    this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
                })

            },

            /**
             * @Description: 查询/重置
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2023-03-09 11:10:40
             */
            clickBtn(type) {
                switch (type.item.fn) {
                    case "primary": // 全部已读

                        this.readAll();
                        break;
                    // case 'reset':
                    //     this.formData.data.forEach((item) => {
                    //         this.listQuery[item.key] = ''
                    //         item.value = ''
                    //     })

                    //     this.getList(1)
                    //     break
                    default:
                        break;
                }
            },
            async clickTitle(data) {
                console.log('data++++++++++++',data);
                
                const _this = this;

                if (data.newsStatus !== "1") {
                    const params = {
                        id: data.id,
                        newsStatus: '1'
                    }
                    await this._fet('/operate/operateNews/save', params).then(res => {
                        if (res.data.code === '200') {
                            let find = this.table_data.find(i => i.id === data.id)
                            find.newsStatus = "1"
                            this.$store.dispatch("getNewsNum")
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg)
                        }
                    })
                }

                let switchObj = {
                    "15":function(){//公文催办
                        let menuId = "01000301"//我的收文menuId
                        let resArr = _this.originRouters.filter(item => item.menuId == menuId);
                        if(resArr.length > 0) {
                            _this.$router.push({path:resArr[0].path})
                        }else {
                            _this.$message.error("暂无权限，请联系管理员开通。")
                        }
                    },
                    "18":function(){//出入通知
                        let menuId = "060302"//出入查询menuId
                        let resArr = _this.originRouters.filter(item => item.menuId == menuId);
                        if(resArr.length > 0) {
                            _this.$router.push({path:resArr[0].path})
                        }else {
                            _this.$message.error("暂无权限，请联系管理员开通。")
                        }
                    },
                    "22":function(){//出入通知
                        let menuId = "060302"//出入查询menuId
                        let resArr = _this.originRouters.filter(item => item.menuId == menuId);
                        if(resArr.length > 0) {
                            _this.$router.push({path:resArr[0].path})
                        }else {
                            _this.$message.error("暂无权限，请联系管理员开通。")
                        }
                    },
                }
                //存储滚动条高度
                let recordsList =  new RecordsList()
                recordsList.set(this.$route.name, {
                    tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
                })


                switchObj[data.newsType]();
            },
            /**
             * @Description: 获取表格数据
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2023-03-09 11:11:20
             */
            getList(isPaging) {
                let data = {}
                if (isPaging) {
                    this.listQuery.pageNum = 1
                }
                this.formData.data.forEach(item => {
                    this.listQuery[item.key] = item.value
                })

                // this.listQuery.typeId = this.tabList.filter(item => item.sel)[0].id

                this.loadingTable = true

                this._fet("/operate/operateNews/list", this.listQuery).then(res => {
                    if (res.data.code === '200') {
                        this.total = res.data.data.totalCount
                        this.table_data = res.data.data.list
                        console.log('this.table_data',this.table_data)
                        this.$store.dispatch("getNewsNum")

                        if(this.total > 0){
                            this.formData.btnList[0].disabled = false;
                        }else{
                            this.formData.btnList[0].disabled = true;
                        }

                        //赋值--存储滚动条高度
                        this.$nextTick(() => {
                            let recordsList =  new RecordsList()
                                if(recordsList.get(this.$route.name)){
                                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                                }
                                console.log(recordsList.get(this.$route.name),'获取滚动条高度',this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop);
                                //移除--存储滚动条高度
                                recordsList.remove(this.$route.name)
                        })
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                    this.loadingTable = false
                })
            },
            /**
             * @Description: 处理请假日期的显示格式
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2023-01-02 20:54:11
             */
            computedTime(leaveStart, leaveEnd) {
                // 当前年份
                let curYear = this.$moment().format("YYYY")
                // 开始日期年份
                let startYear = this.$moment(leaveStart).format("YYYY")
                // 结束日期年份
                let endYear = this.$moment(leaveEnd).format("YYYY")
                let format = ''
                if (curYear === startYear && curYear && endYear) {
                    format = 'MM-DD HH:mm'
                } else {
                    format = 'YYYY-MM-DD HH:mm'
                }
                let startDate = this.$moment(leaveStart).format(format)
                let endDate = this.$moment(leaveEnd).format(format)
                return `${startDate} - ${endDate}`
            },
            changeSel(data){
                if (data.key === 'newsStatus') {

                    this.getList(1)
                }
            },
            // 全部已读
            readAll(){
                this._fet("/operate/operateNews/readAll", {
                    receiverId:this.$store.state.userId,
                    newsTypeList:this.listQuery.newsTypeList
                }).then(res =>{
                    if (res.data.code === '200') {
                        this.$message.success("修改成功")
                        this.getList(1)
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })

            }
        },
    };
</script>

<style lang="scss" scoped>
.notification-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    // background-color: #edf0f7;

    .main-top {
        display: flex;
        justify-content: space-between;

        .back {
            display: flex;
            align-items: center;
            .global-page-b {
                display: inline-block;
                width: 29px;
                height: 29px;
                background: #FFFFFF;
                box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.17);
                border-radius: 50%;
                cursor: pointer;
                .global-page-icon {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    margin: 7.5px 0 0 7.5px;
                    background-color: #FFFFFF;
                }
            }
        }
    }

    .expand-filter-wrapper {
        border-bottom: 1px solid #F2F4F7;
    }
    .content-wrapper {
        margin: 10px;
    }

    .status-box {
        display: flex;
        align-items: center;
        justify-content: center;

        .gray {
            width: 6px;
            height: 6px;
            background: #CDCDCD;
            border-radius: 50%;
            margin-right: 4px;
        }

        .green {
            width: 6px;
            height: 6px;
            background: #46CC95;
            border-radius: 50%;
            margin-right: 4px;
        }
    }
    .content{
        display: block;
        max-width: 350px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color:#3c7efe
    }

}
</style>
